<template>
  <div class="bienvenida-contenedor">
      <h1>Bienvenida de nuevo</h1>
      <p>{{ nombreUsuaria }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nombreUsuaria: 'Administradora'
    }
  }
}
</script>

<style lang="scss" scoped>
  .bienvenida-contenedor{
    width:100%;
    height: calc(100vh - 120px);
    display: flex; align-items:center; justify-content:center; flex-direction: column;

    & h1{
      font-size:30px;
      font-weight:bold;
      margin:0;
    }
    & p { 
      font-size: 28px;
      font-weight: bold;
      margin:0;
    }
  }
</style>